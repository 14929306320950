.Navbar {
  background: #fff;
}

.logo__nav {
  display: flex;
}

.logo__line {
  border-left: 2px solid #e2e5e8;
  height: 40px;
  margin-left: 1.6rem;
}

a.pine__links.nav-link {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.004em;
  font-weight: 500;
  color: #4f5b6e;
  margin-left: 1.5rem;
}

@media screen and (max-width: 768px) {
  a.pine__links.nav-link {
    margin-left: 0.5rem;
    margin-bottom: 1rem;
  }
  .logo__line {
    display: none;
  }
}

a.pine__links.nav-link img,
#support img,
svg {
  margin-right: 1rem;
}

.support-nav .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
}

svg.navIcon path {
  fill: #939ead;
}

svg.activeIcon path {
  fill: #facf19;
}

a.pine__links.nav-link.active,
svg.navIcon path {
  font-weight: 500;
  background: #fcf8e8;
  border-radius: 20px;
  color: #081a38;
}

#support {
  margin-left: 0;
  background-color: #f5f6f7;
  border-radius: 50px;
  color: #4f5b6e;
  text-decoration: none;
  padding: 1rem;
  font-weight: 500;
}

.btn {
  border-radius: 10px;
}

.active,
.btn:hover {
  background-color: rgb(247, 152, 10);
  color: white;
}

.ml-10 {
  margin-left: 80px !important;
}

.circle {
  border: 1px solid #e3e3e3;
  border-radius: 50%;
  padding: 15px;
}

.button > .nav-link {
  background-color: #fff !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.button.nav-item.dropdown {
  background-color: #f5f6f7;
  border-radius: 20px;
}

.person {
  width: 40px;
  height: 40px;
}

.support-text {
  color: #4f5b6e;
}

.fa-comments {
  color: #939ead;
}

a.profile__title.dropdown-item,
a.dropdown-item,
a.profile__logout.dropdown-item {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  font-weight: 500;
  margin: 0.9rem 1rem;
  background-color: transparent !important;
}

a.notification {
  margin: 0;
  padding: 0;
}

.settings_nav {
  color: #4f5b6e !important;
  text-decoration: none;
}

a.profile__logout.dropdown-item {
  color: #f25a68;
}

.dropdown-menu.show {
  border: 1px solid #e2e5e8;
  box-shadow: 0px 6px 24px rgba(8, 26, 56, 0.1);
  border-radius: 12px;
  position: absolute;
  left: -12rem;
}

@media screen and (max-width: 768px) {
  .dropdown-menu.show {
    left: 0;
  }
}

.call__container {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin: 1.2rem 1.7rem;
}

.call__icon {
  margin-right: 2rem;
}

.call__icon a {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #081a38;
  font-weight: 600;
}

.call__span {
  background: #e2e5e8;
  border-radius: 6px;
  padding: 0.5rem;
  margin-right: 1.4rem;
}

/* .nav-link.active {
    background-color: #FCF8E8 !important;
    color: #081A38 !important;
    font-weight: 500;
}

.nav-link.active > .fas {
    color: #FACF19 !important;
}

.nav-link {
    color: #4F5B6E !important;
} */

.nav-link > .fas {
  color: #939ead !important;
}

.notification__container {
  padding: 1rem;
}

.notify__container {
  border-bottom: 1px solid #e2e5e8;
  padding: 1rem 1rem 0.5rem 1rem;
}

.notify__title p {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  font-weight: 500;
  margin-bottom: 0;
}

.notify__title small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #939ead;
}
