.evenlope {
    width:80px;
    height: 80px;
}

.header__text{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.006em;
    color: #081A38;
}

.verify-text {
    width: 27rem;
    margin: 0 auto;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #939EAD;
}

.tel-list > li{
    display: inline-block;
    width: 46px;
    height: 64px;
    border-radius: 10px;
    margin-right: 10px;
}

li .form-control {
    height: 54px !important;
    background: #F8F9FA;
    border: 1px solid #E2E5E8;
    border-radius: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #081A38;
}

.verify-button {
    width: 23rem;
    height: 56px;
}

.mt-10 {
    margin-top: 5em !important;
}

.mt-8 {
    margin-top: 50px;
}

.underline {
    text-decoration: underline;
}

@media screen  and (max-width: 768px) {
    .verify-text{
        width: auto;
    }
    .verify-button{
        width: 100%;
    }
}