.invite__line{
    border-bottom: 1px solid #E2E5E8;
    margin: 1.5rem 0;
}

.modal__header {
    align-items: center;
}

.modal__header h3{
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #081A38;
}

.send_invite_btn{
    background: #F5F6F7;
    border-radius: 7px;
    padding: .9rem 2rem;
    border: none;
    color: #4F5B6E;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}