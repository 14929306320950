.adddriver_btn{
    display: flex;
    column-gap: 1rem;
    align-items: center;
}

.vehicle__container{
    width: 580px;
}

@media screen and (max-width: 768px) {
    .vehicle__container{
        width: auto;
    }  
}

button.btn.btn-white.btn-block{
    background: #F5F6F7;
    border-radius: 7px;
    padding: .9rem 1rem;
    font-size: 14px;
    line-height: 22px;
    color: #4F5B6E;
    font-weight: 500;
}

.adddriver__body{
    background: #EDEADF;
}

.fleet__top_line, .fleet__bottom_line{
    position: relative;
    width: 540px;
    height: 0px;
    left: 0;
    right: 0;
    top: 0;
    border: 1px solid #E2E5E8;
}

@media screen  and (max-width: 768px) {
    .fleet__top_line, .fleet__bottom_line{
        position: relative;
        width: auto;
    }
}

.basic__info{
    margin-top: 1.5rem;
}

.basic__h3{
    font-size: 20px;
    font-weight: 500;
    color: #081A38;
    margin-bottom: .5rem;
    letter-spacing: 0.006em;
    line-height: 30px;
}

.document__upload{
    margin-top: 3rem;
}

.fleet__bottom_line{
    top: 30px;
}

.document__container{
    display: flex;
    justify-content: space-between;
    background: #3B3B3A;
    border-radius: 4px;
    padding: .8rem 1rem;
    align-items: center;
    margin-bottom: 1rem;
}

.document__upload__item{
    display: flex;
    column-gap: 1rem;
    color: #F5F6F7;
    align-items: center;
}

.document__upload__item__img p{
    padding: 0;
    margin: 0;
}

.document__upload__item__icon{
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.004em;
    color: #FACF19;
}

.vehicle__type{
    margin-bottom: 1.5rem;
}

.vehicle__truck__types{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.vehicle__truck{
    border: none;
}

.vehicle__right__arrow , .vehicle__left__arrow{
    top: 70px;
    right: 130px;
}

.vehicle__left__arrow{
    left: 100px;
}