.cash__btn{
    border: 1px solid #E2E5E8;
    border-radius: 8px;
    padding: .7rem 1.7rem;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.004em;
    color: #081A38;
    margin: 0 1.5rem;
}

.cardInfo__header{
    background: #3B3B3A;
}

.cardInfo__header h3{
    color: #fff;
}

.cardInfo__body{
    background: #EDEADF;
}

.card__info__body__content{
    margin: 1.3rem 0;
}

.card__info__content h6{
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #4F5B6E;
    margin: 0;
}

.card__info__content p{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #939EAD;
    margin: 0;
}

.card__line{
    margin-top: 1rem;
}

@media screen and (max-width: 768px) {
    .cash__btn{
        margin: 0;
    }
}