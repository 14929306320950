.settings {
  background-color: #edeadf;
  padding: 3rem 5rem;
  height: 100vh;
}
@media screen and (min-width: 2560px) {
  .settings {
    height: 100vh;
  }
}
.siderbar_header {
  margin-bottom: 2rem;
}

.siderbar_header h3 {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.006em;
  color: #081a38;
  margin: 0;
  font-weight: 500;
}

.siderbar_header small {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
}

.siderbar_body .sidebar__ul {
  padding: 0;
  margin: 0;
}

.sidebar__li {
  list-style: none;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  margin: 1.5rem 0;
}

.sidebar_active {
  color: #081a38;
  font-weight: 600;
}

.sidebar_active::before {
  content: url('../../../assets/settings/dot.png');
  margin-right: 0.5rem;
}

.content {
  padding: 2rem;
  background: #ffffff;
  border: 1px solid #fcf8e8;
  box-shadow: 0px 2px 16px rgba(226, 229, 232, 0.32);
  border-radius: 8px 8px 0px 0px;
}

.cameraImg {
  position: relative;
  top: 30px;
  right: 30px;
}

#filetype {
  display: none;
}

.previewImg {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.bankInfo {
  padding: 0 4rem;
}

.user__header {
  display: flex;
  justify-content: space-between;
}

.user__select__options {
  display: flex;
  column-gap: 1.2rem;
}

.invite_btn {
  padding: 0.6rem;
}

.bankInfo__content {
  margin-top: 2rem;
}

.bankInfo__title h3 {
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.006em;
  color: #081a38;
  font-weight: 600;
  margin: 0;
}

.bankInfo__content__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
  border-radius: 4px;
  padding: 1rem;
  margin: 1.5rem 0;
}

.bankInfo__content__item__left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 1rem;
}

.bankInfo__content__item__left h4 {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #081a38;
  font-weight: 600;
}

.bankInfo__content__item__left small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #4f5b6e;
  font-weight: normal;
}

.bankInfo__content__item__right {
  display: flex;
  column-gap: 1rem;
}

/*  manage subscription */
.card_container {
  background: #f5f6f7;
  border-radius: 10px;
}

.form-check-input:checked {
  background: #facf19;
  border-color: #facf19;
  background-repeat: no-repeat;
}

.subscription_font_size {
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #000000;
  letter-spacing: 0.006em;
}

.small_sub {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .settings,
  .content {
    padding: 1rem;
  }
  .bankInfo {
    padding: 0;
  }
}
