.booking__detail__address{
    background-color: #3B3B3A;
    padding: 1rem;
    margin-top: 0;
    color: #fff;
    align-items: center;
    margin-bottom: 1rem;
}

.booking__detail__status{
    background: #E3FCF3;
    border-radius: 22px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #29CC92;
    padding: .3rem .6rem;
}

.booking__contact__detail{
    display: flex;
    background: #FAF9F2;
    border: 1px dashed #FACF19;
    border-radius: 6px;
    padding: 1rem;
    align-items: center;
    column-gap: 1.4rem;
}

.booking__contact__detail__name a{
    text-decoration: none;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.002em;
    color: #939EAD;
}

.booking__details__amount h6{
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #081A38;
    margin: 0;
    padding: 0;
}

.booking__detail__line{
    border-top: 1px solid #E2E5E8;
    margin-top: 1rem;
    padding-top: 1rem;
}