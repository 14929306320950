.border-warning {
  border-radius: 5px;
}

.home {
  background: #edeadf;
  padding: 0 4rem;
  height: 100vh;
}

@media screen and (min-width: 2560px) {
  .home {
    height: 100vh;
  }
}

.home__content {
  padding: 20px 0;
}

.home__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
}

.object_fit {
  object-fit: contain;
}

.home_content_col {
  /* padding: .5rem; */
  background: #ffffff;
  border-radius: 8px;
}

.home_content_grid {
  display: grid;
  height: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr;
}

.home_content_col__left {
  border: 1px solid #facf19;
  box-shadow: 0px 2px 16px rgba(226, 229, 232, 0.32);
  border-radius: 8px;
}

.mobile_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
}

.mobile_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #939ead;
}

#home__select {
  width: 40%;
  background-color: #f5f6f7;
  border: none;
  color: #4f5b6e;
  font-weight: 500;
  outline: none;
}

.driver_content__icon {
  width: 50px;
}

.total__amount {
  margin-top: 0.5rem;
  padding-left: 1rem;
}

.total__amount p {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  margin: 0;
}

.total__amount h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.006em;
  color: #081a38;
}

.total__trip {
  border-left: 1px solid #facf19;
  text-align: center;
}

.total__trips {
  margin-top: 1rem;
  padding-left: 0;
}

.account__online {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9rem;
  gap: 1rem;
  border-radius: 4px;
}

.account_online__content h6 {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.004em;
  color: #222222;
}

.account_online__content p {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #333333;
}

.first-row {
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* BOOKINGS */
.bookings {
  background: #ffffff;
  border: 1px solid #fcf8e8;
  box-shadow: 0px 2px 16px rgba(226, 229, 232, 0.32);
  border-radius: 8px 8px 0px 0px;
  margin-top: 1.5rem;
  padding: 1.5rem;
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid #edeff5;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 0.5rem;
}

#search__bar {
  background: transparent;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.004em;
  color: #a5abc1;
  border: none;
  outline: none;
}

.booking__header {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  padding-bottom: 1rem;
}

.booking__select {
  background-color: #f5f6f7;
  border: none;
  color: #4f5b6e;
  font-weight: 500;
  outline: none;
  width: auto;
  font-size: 14px;
}

.btn__search {
  border: none;
  padding: 0;
  background-color: transparent;
}

.booking_table_title {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.002em;
  color: #081a38;
}

.booking__header h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  letter-spacing: 0.006em;
  color: #081a38;
}

.booking__rows {
  border-top: 1px solid #edeff5;
  border-bottom: 1px solid #edeff5;
}

.booking__name {
  display: flex;
  align-items: center;
}

.booking__row a {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: 0.004em;
  color: #4f5b6e !important;
  text-transform: capitalize;
}

.booking__small small {
  font-size: 13px !important;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #939ead;
}

.show-grid {
  padding: 0;
}

.booking__details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #ffffff;
  border-radius: 16px 0px 0px 16px;
}

.google__maps {
  height: 100vh;
}

.booking_detail__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e2e5e8;
}

.booking__status {
  background: #fce3e5;
  border-radius: 22px;
  width: 50px;
  height: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 5px;
  text-align: center;
  padding: 0.5rem;
  color: #f25a68;
}

/* .booking__line {
  position: absolute;
  width: 380px;
  height: 0px;
  left: 0px;
  top: 92px;
} */

.booking_body__container {
  padding: 0.8rem 1.5rem 1.5rem 1.5rem;
}

.booking__detail__body {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #edeff5;
}

.booking__body__title {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #939ead;
}

.booking__body__response {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  font-weight: 500;
}

.booking__address {
  margin-top: 2rem;
  display: flex;
}

.book__address__pickup h6 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  margin: 0;
}

.bg-yellow {
  background-color: #facf19;
}

/* .booking_bottom_line {
  position: absolute;
  width: 390px;
  height: 0px;
  left: 0px;
  border-top: 1px solid #e2e5e8;
} */

.booking__footer {
  padding: 0.8rem 1.5rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e2e5e8;
}

.btn__reject {
  background: #f5f6f7;
  border-radius: 7px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  font-weight: 500;
  padding: 0.8rem 2rem;
}

.btn__accept {
  background: #facf19;
  border-radius: 7px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #081a38;
  font-weight: 600;
  padding: 0.5rem 2rem;
}

.table-button:focus,
.table-button:hover,
.table-button,
.show > .table-button {
  background-color: transparent !important;
  color: #4f5b6e !important;
  border: none !important;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .home {
    height: auto;
    padding: 1rem;
  }

  .home__grid {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }

  .account__online {
    grid-template-columns: 1fr;
  }

  .total__trips {
    margin-top: 1rem;
  }
  .booking__select {
    width: 140px;
  }
  .booking__header h4,
  .booking__row {
    font-size: 15px;
    line-height: 22px;
  }
  .booking_table_title {
    font-size: 14px;
  }
  .booking__footer {
    gap: 1rem;
  }
  .driver_content__icon {
    width: 30px;
  }
}

@media screen and (max-width: 769px) {
  .home__grid,
  .home_content_grid {
    display: flex;
    flex-direction: column;
  }
  .total__trip {
    border-top: 1px solid #facf19;
  }
  .booking__details {
    display: inline;
    grid-template-columns: 1fr;
  }

  .booking__footer {
    padding: 0.8rem 0.7rem;
  }
  .btn__accept,
  .btn__reject {
    padding: 0.7rem;
  }
  .booking__header {
    flex-direction: column;
    gap: 0.4rem;
  }
}

@media screen and (max-width: 320px) {
  .booking__line,
  .booking_bottom_line {
    width: 300px;
  }
}
