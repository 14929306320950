.business__top{
    margin-top: 5rem;
}

 .business__paragraph{
    width: 60%;
    margin: 0 auto;
}

@media screen and (max-width: 526px) {
    .business__paragraph{
        width: auto;
        text-align: center;
    }

    .business__top{
        padding: 1rem;
        margin: 2rem 0 5rem 0;
    }
}