.add__delivery{
    text-decoration: none;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #3B3B3A;
    font-weight: 500;
}

.select__truck .f-16{
    color: #4F5B6E;
}

.truck__types{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
}

.each__truck{
    border: 1px solid #E2E5E8;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
}

.each__truck p{
    margin: 0;
    padding-top: 1rem;
}

.truck__active{
    border: 1px solid #FACF19;
}

.left__arrow{
    position: absolute;
    width: 32px;
    height: 32px;
    left: 0;
    top: 410px;
}

.right__arrow{
    position: absolute;
    width: 32px;
    height: 32px;
    right: 30px;
    top: 410px;
}

.modal-content{
    border-radius: 15px !important;
}

.fare__container{
    background: #EDEADF;
    border-radius: 10px;
}

.fare__content{
    background-color: #FFFFFF;
    padding: 2rem;
    margin: 3rem;
    border-radius: 6px;
}

.active_truck{
    border: 1px solid #FACF19;
}

@media screen and (max-width: 526px) {
    .fare__content{
        margin: 2rem 0;
        padding: 1rem;
    }
}

.fare__cost_line{
    border-top: 1px solid #E2E5E8;
    margin-bottom: 1rem;
    margin-top: 2.5rem;
}

.fare__col-left{
    border-right: 1px solid #E2E5E8;
}