.login {
  height: 100vh !important;
  background-color: #fff;
}

.trust {
  margin-top: 18.2%;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: 0.008em;
  width: 9em;
  color: #000000;
}

.truck {
  margin-top: 10.69%;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
  font-weight: 400;
}

.copyright {
  position: absolute;
  bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #4f5b6e;
}

.login__form__control {
  border: 1px solid #e2e5e8;
  border-radius: 8px;
  caret-color: #facf19;
}

.login__form__control:focus {
  border: 1px solid #facf19;
  border-radius: 8px;
  background-color: transparent !important;
  box-shadow: none;
}

.login__label {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #939ead;
}

.login__links {
  color: #081a38;
  font-weight: 500;
  text-decoration: underline;
}

.img-thumbnail {
  background: #fcf8e8;
  border: none;
  height: 30px;
  width: 50px;
  margin-top: 4%;
}

.login__right h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.006em;
  color: #081a38;
}

.btn-login {
  background: #facf19;
  border-radius: 8px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #081a38;
  padding: 0.9rem;
  font-weight: 500;
}

.login__right .f-16 {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #939ead;
}

.deliver {
  color: #facf19;
}

.click {
  text-decoration: underline;
  text-decoration-style: solid;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.password-toggle-icon {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}

.eye {
  color: #7b7b7b;
  margin-right: 10px;
  float: right;
  margin-top: -30px;
}
.eye:hover {
  color: #667470;
  cursor: pointer;
}

.bg-pine {
  background-color: #fcf8e8;
  height: 100vh;
}

.mt-30 {
  margin-top: 20vh;
}

.form-input {
  height: 52px;
}

@media screen and (max-width: 768px) {
  .trust {
    width: auto;
  }
}
