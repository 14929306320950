
a.dropdown__danger.dropdown-item{
    color: #F25A68 !important;
}

.user__name__h6{
    margin: 0;
}

.user__name small{
    font-size: 14px;
}

.driver_content{
    position: absolute;
    bottom: 1px;
}

.driver_content__header{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 8px;
    padding: .9rem;
    margin: 10px;
    column-gap: 1rem;
    align-items: center;
}

.driver_content__header__text h6{
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #081A38;
    font-weight: 700;
    margin: 0;
}

.driver_content__header__text small{
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.002em;
    color: #939EAD;
}

.driver__small{
    display: flex;
    column-gap: .5rem;
    align-items: center;
    color: #939EAD;
}

.document__upload__item__img small{
    font-size: 10px;
    letter-spacing: 0.002em;
    line-height: 12px;
}

.document__upload__item__img p{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.004em;
}

.document__upload__item__icon label{
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.004em;
}

.driver__detail_row{
    background: #FCF8E8;
    border-radius: 4px; 
    padding: 1rem;
    margin: .5rem;
}

.complete_info-row{
    border-bottom: 1px solid #E2E5E8;
    padding-bottom: 1rem;
}

.complete_info-row_col_right{
    border-right: 1px solid #E2E5E8;
}

.complete_info-row_col h6{
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #081A38;
    margin: 0;
}

.complete_info-row_col small{
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.002em;
    color: #939EAD;
}

.close_transit{
    position: absolute;
    z-index: 100;
    right: 0;
}

.trip__history{
    background: #EDEADF;
    padding: 1rem;
}

.trip__history__content{
    margin-top: 1.4rem;
}

.trip__history__content__container{
    background: #3B3B3A;
    border-radius: 4px;
    color: #FFFFFF;
}

.trip__history__content__item{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
}

.trip__history__content__item__header{
    display: flex;
    align-items: center;
    column-gap: .6rem;
}

.trip__history__content__item__header__content h6{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.004em;
    padding: 0;
    margin: 0;
}

.trip__history__content__item__header__content small{
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.002em;
}

.trip__history__content__item__body p{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.004em;
    margin-bottom: .3rem;
}

.trip__history__content__item__body .trip__completed__span{
    color: #29CC92;
}

.trip__history__date{
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.002em;
    opacity: .7;
}

.trip__history__content__item__body {
    display: flex;
    flex-direction: column;
}
