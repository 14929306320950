.custom-radio {
    background-color: #F8F9FA;
    padding: 15px;
    border-radius: 8px; 
}

.custom-radio .custom-control-label::before {
    border: 1px solid #939EAD;  /* orange */
    border-radius: 50%;
}

/* This is the checked state */
.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-color: #FACF19; 
    border-radius: 50%;
}

/* active state i.e. displayed while the mouse is being pressed down */
.custom-radio .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    border: 1px solid #FACF19;  
    /* background-color: #FACF19;  */
}
    
/* the shadow; displayed while the element is in focus */
.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 123, 255, 0.25); /* pink, 25% opacity */
}

.mt-10 {
    margin-top: 10vh;
}

.envelope {
    width: 80px;
    height: 80px;
}

