.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* margin-right: 0 !important; */
}

.swiper-button-prev {
  content: url(../../../../../../assets/calcfare/leftarr.png);
  height: auto;
  width: auto;
  left: -30px;
  z-index: 100;
}

.swiper-button-next {
  content: url(../../../../../../assets/calcfare/rightarr.png);
  height: auto;
  width: auto;
  right: -30px;
  z-index: 100;
}
