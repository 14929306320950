.fleets_container {
  background: #ffffff;
  border: 1px solid #fcf8e8;
  box-shadow: 0px 2px 16px rgb(226 229 232 / 32%);
  border-radius: 8px 8px 0px 0px;
  padding: 0 1.5rem;
}

.fleets_header .fleets_header_ul,
.fleet_header_ul {
  padding: 0;
  margin: 0;
  display: flex;
  column-gap: 1.4rem;
}

.fleet_header_ul {
  border-bottom: 1px solid #edeff5;
}

.fleet_header_ul .fleets__li {
  list-style: none;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.004em;
  color: rgba(79, 91, 110, 0.563);
  padding: 1rem 0;
}

.fleets_header_ul .fleets__li {
  list-style: none;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.004em;
  color: rgba(79, 91, 110, 0.563);
}

.fleet_header_ul .fleets_active,
.fleets_header_ul .fleets_active {
  color: #081a38;
  font-weight: 600;
  list-style: none;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #facf19;
}

.fleets_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.fleets__select {
  display: flex;
  column-gap: 1rem;
}

.trip__status {
  background: #e3fcf3;
  border-radius: 22px;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  color: #29cc92 !important;
  width: fit-content;
  padding: 0.2rem 1rem;
}

@media screen and (max-width: 768px) {
  .fleets_line {
    width: 290px;
  }
  .fleets_search {
    display: inline;
    row-gap: 1rem;
  }
  .fleets__select {
    margin: 1rem 0;
  }
}
