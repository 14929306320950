.booking__row .trip__status {
  background: #e3fcf3;
  border-radius: 22px;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  color: #29cc92 !important;
  padding: 0.2rem 1rem;
}

.btn__cancel {
  background: #f25a68;
  border-radius: 7px;
  color: #fce3e5;
  padding: 0.5rem 2rem;
}

.booking__status_queued,
.booking__status_new {
  color: #0d99f2;
  background: #e3f2fc;
  border-radius: 22px;
  padding: 0.2rem 0.9rem;
  font-weight: 500;
}

.booking__status_new {
  background: #fce3e5;
  color: #f25a68;
}

a.cancel {
  color: #f25a68;
}

.booking__status_transit {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #f1872d;
  background: #fcf2e3;
  border-radius: 22px;
  padding: 0.2rem 0.9rem;
}

.booking__status_complete {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #29cc92;
  background: #e3fcf3;
  border-radius: 22px;
  padding: 0.2rem 0.9rem;
}

.driver_content,
.drivers_content {
  position: absolute;
  bottom: 1px;
  background: #ffffff;
  border-radius: 8px;
  width: -webkit-fill-available;
  margin: 10px;
}

.drivers_content {
  width: 360px;
  padding: 0.9rem;
  margin: 10px;
}

.driver_content__header {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  align-items: center;
}

.driver_content__header__text h6 {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #081a38;
  font-weight: 700;
  margin: 0;
}

.driver_content__header__text small {
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #939ead;
}

.booking__rating_container {
  padding: 1.3rem;
}

.booking__rating_container h5 {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.002em;
  color: #939ead;
  font-weight: 500;
}

.booking__rating__stars span {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: #081a38;
  font-weight: 500;
  margin-left: 0.5rem;
}

.trip_rating {
  margin-top: 1rem;
}

.trip_rating .trip_rating__stars {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin: 0;
  padding: 0;
}

.trip_rating__stars li {
  list-style: none;
  background: #e2e5e8;
  border-radius: 100px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #081a38;
  padding: 0.2rem 0.5rem;
}

.completed_info {
  background: #edeadf;
}

.completed_info__body {
  padding: 1.2rem;
  background: #ffffff;
  border-radius: 6px;
  margin: 2rem;
}

.completed_info__header {
  margin-top: 6rem;
}

.completed_info__header h6 {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #081a38;
  font-weight: 700;
  margin: 1rem 0 0 0;
}

.completed_info__header small {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #939ead;
  font-weight: 500;
}

.completed_info__header p {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #081a38;
  font-weight: 500;
  margin: 0;
}

.completed_info__header p span {
  color: #939ead;
  font-weight: 400;
  font-size: 12px;
}

.completed_info__body__content .booking__detail__body {
  border: none;
}

.completed_info_body_footer {
  border-top: 1px solid #e2e5e8;
  margin-top: 1rem;
}

.completed_info_body_footer p {
  padding-top: 1rem;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #081a38;
  font-weight: 600;
}

.complete_info-row {
  border-bottom: 1px solid #e2e5e8;
  padding-bottom: 1rem;
}

.complete_info-row_col_right {
  border-right: 1px solid #e2e5e8;
}

.complete_info-row_col h6 {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #081a38;
  margin: 0;
}

.complete_info-row_col small,
.booking__small_contact {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #939ead;
  text-decoration: none;
}

.close_transit {
  position: absolute;
  z-index: 100;
  right: 0;
}

.active_status {
  color: #29cc92;
}
/* 
@media screen and (max-width: 768px) {
  .driver_content {
    width: 200px;
  }
}

@media screen and (max-width: 526px) {
  .driver_content {
    width: 330px;
  }
}

@media screen and (max-width: 320px) {
  .driver_content {
    width: 280px;
  }
} */
