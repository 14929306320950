* {
  font-family: 'DM Sans', sans-serif;
}

body {
  background-color: #edeadf !important;
}

.App {
  overflow-x: hidden;
}
