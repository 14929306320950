#create__booking__modal {
  max-width: 1200px;
  height: 100vh;
}

.create__google__maps {
  height: 110vh;
}

.create__booking__container {
  position: absolute;
  top: 20px;
  left: 50px;
  width: 595px;
}

.create__booking__container__content,
.booking__description__container {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(232, 237, 250, 0.25);
  border-radius: 8px;
  padding: 1.5rem;
}

.create__booking__container__content h1 {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.006em;
  color: #081a38;
}

.address__icon {
  position: relative;
  top: 40px;
}

.create__booking__address {
  position: relative;
  right: 20px;
}

.pickip__time {
  margin-top: 2rem;
}

.pickip__time h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.006em;
  color: #081a38;
  margin-bottom: 1rem;
}

.btn_clear {
  background: #ffffff;
  border: 1px solid #e8edfa;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.006em;
  color: #081a38;
  padding: 0.5rem 1rem;
}

.booking__description__container {
  border-radius: 0px 0px 8px 8px;
  /* height: 550px;
    overflow:  auto; */
}

.slick-track {
  display: flex !important;
}

.estimate_delivery {
  background: #fcf8e8;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
}

.estimate_delivery h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.006em;
  color: #081a38;
}

.delivery_confirm {
  margin-top: 6rem;
}

.delivery_confirm p,
.contact__person {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #939ead;
}

.delivery_confirm .change,
.contact__person .change {
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.004em;
  text-decoration-line: underline;
  color: #3b3b3a;
  font-weight: 500;
}

.contact__person__name {
  color: #3b3b3a;
  font-weight: 500;
}

.booking__type .cash__btn {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .create__booking__container {
    width: 70%;
    left: 0;
  }
  .create__booking__container__content h1 {
    font-size: 20px;
  }
  .pickip__time h5 {
    font-size: 16px;
  }
  .estimate_delivery h6 {
    font-size: 14px;
  }
  .delivery_confirm p,
  .contact__person,
  .contact__person__name,
  .change {
    font-size: 14px;
  }
}

@media screen and (max-width: 526px) {
  .create__booking__container {
    width: 100%;
    left: 0;
  }
}
