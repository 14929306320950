.neutral__paragraph{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #4F5B6E;
}

.reject__footer{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #E2E5E8;
    padding-top: 20px;
}

.btn__modal__reject{
    background: #F25A68;
    color: #fff;
    font-weight: 400;
    margin-left: 1rem;
}