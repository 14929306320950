.shipper__home{
    padding: 2rem 4rem;
}

.shipper__home_container{
    border: 1px solid #FCF8E8;
    background-color: #fff;
    padding: 2rem 6rem 5rem 6rem;
}

.shipper__home_container_title{
    text-align: center;
}

.shipper__home_container_title h1{
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.006em;
    color: #081A38;
}

.shipper__home_container_title p{
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.004em;
    color: #939EAD;
}

.shipper__home__options{
    margin-top: 3rem;
}

.shipper__home__options_item{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.4rem;
}

.shipper__home__options_item h2{
    font-size: 26px;
    line-height: 42px;
    letter-spacing: 0.006em;
    color: #FFFFFF;
}

.shipper__home__book_item, .shipper__home__fare_item, .shipper__home__payment_item{
    background: #56DBC1;
    border-radius: 8px;
    padding: 2rem;
    color: #fff;
}

.shipper__home__options_item_icons{
    margin-top: 5rem;
}

.shipper__home__fare_item{
    background: #776BDB;
}

.shipper__home__payment_item{
    background: #F075A1;
}

.home__icon{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.4rem;
}

.home__icon img{
    justify-self: end;
}

.book_icon{
    margin-top: 8rem;
}

.category__truck__container{
    column-gap: 0;
}

.category__trucks{
    box-shadow: 0px 6px 24px rgba(8, 26, 56, 0.1);
    border-radius: 0;
}

.first__category__truck{
    border-radius: 8px 0px 0px 8px;
}

.last__category__truck{
    border-radius: 0px 8px 8px 0px;
}

.truck__left{
    top: 555px;
}

.truck__right{
    top: 555px;
}

.category__left__arrow{
    top: 390px;
}

.category__right__arrow{
    top: 390px;
}

@media screen and (max-width: 768px) {
    .shipper__home{
        padding: 1rem;
    }
    .shipper__home_container{
        padding: 1rem;
    }
    .shipper__home__options_item{
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
    .modal__header h3{
        font-size: 14px;
    }
    .send_invite_btn{
        padding: .9rem .7rem;
    }
    .login__right .f-16{
        font-size: 14px;
    }
    /* .swiper-slide{
        width: 140px !important;
    } */
}